<template>
    <div>
        <div id="chart1" style="width:600px; height: 400px;"></div>
        <div id="echarts-01"  style="width:600px; height: 400px;" ref="Echart01"></div>
        <h2>{{title}}</h2>
    </div>
</template>
<script>
    // import echarts from "echarts"
    var echarts = require("echarts");
    export default {
        data() {
            return {
                optionPie: null,
                echartsPie: null,
                num: 0,
                timer: null,
                title: ''
            }
        },
        mounted() {
            this.initData();
        },
        methods: {
       changeData02_1(name){
			var colors = [
				{color: "#e54d42"},
				{color: "#f37b1d"},
				{color: "#fbbd08"},
				{color: "#8dc63f"},
				{color: "#39b54a"},
				{color: "#1cbbb4"},
				{color: "#0081ff"},
				{color: "#6739b6"},
				{color: "#9c26b0"},
				{color: "#e03997"},
				{color: "#a5673f"},
				{color: "#58e8e4"},
			]
			var data = this.$store.state.OURDatalist;
			for(var i = 0; i < data.length; i ++){
				this.dataLists_02_1.tatobar.splice(i,1,data[i].name);
				var datas = {
					value: data[i].failNums + data[i].passNums,
					name: data[i].name,
					itemStyle: colors[i],
				}
				this.dataLists_02_1.datas.splice(i,1,datas);
			}
		},



            initData() {
            //初始化echarts实例
                var my1 = echarts.init(this.$refs.Echart01);
                this.echartsPie = my1;

                this.optionPie = {
                    title: {
                        text: '饼图'
                    },
                    series: [{
            type: "pie",
            radius: ["30%", "85%"],
            center:["40%","50%"],            
            avoidLabelOverlap: false,
            // 标签
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                // 自定义标签
                formatter: [
                  '{num|{d}'+'%}',
                  '{bt|{b}}'
                ].join('\n'),
                rich: {
                  num:{
                    fontSize: '24',
                    lineHeight:'100'
                  },
                  bt: {
                    fontSize: '30'
                  }
                }
              }
            },

           

            // 视觉引导线隐藏
            labelLine: {
              normal: {
                  show: false
              }
            },

                        data: [
                            { name: "智联招聘", value: 600,selected:false },
                            { name: "51job", value: 310,selected:false },
                            { name: "拉钩", value: 200,selected:false },
                            { name: "Boss直聘", value: 800,selected:false }
                        ]
                    }]
                };

              
                //使用制定的配置项和数据显示图表
                this.echartsPie.setOption(this.optionPie);
                //开启定时器
                this.setTimeToDo();

                // 鼠标划出
                this.echartsPie.on('mouseout',()=>{
                    this.setTimeToDo()
                })

                // this.setHighLight();
                // const data = this.optionPie.series[0].data;
                // let debounce = null;
                // this.myChart.on('mouseover', params => {
                //     clearInterval(this.timer);
                //     // 鼠标快速移动时，会导致mouseover里的代码来不及执行，引起事件延迟，出现多个扇形区域同时被选中的现象，所以有必要设置防抖函数
                //     debounce && clearTimeout(debounce);
                //     debounce = setTimeout(() => {
                //         const data = this.option.series[0].data;
                //         data.forEach(e => e.selected = false);
                //         data[params.dataIndex].selected = true;
                //         this.myChart.setOption(this.option);
                //         this.num = params.dataIndex;
                //         this.title = params.name;
                //     }, 300);
                // })
                // this.myChart.on('mouseout', params => {
                //     this.setTimeToDo();
                //     this.setHighLight();
                // })
                // // 当切屏时，定时器容易执行异常，所以如果用户切屏，就暂停定时器
                // window.onblur = () => {
                //     clearInterval(this.timer);
                // }
                // window.onfocus = () => {
                //     this.setHighLight();
                // }
            },

        
 
		beginRendering_02_1(){ //映射数据
			
			this.mycharts_02_1.setOption(this.option_02_1);
		},
		left_02_1_Orur(name){
			this.id_02_1 = -1
			this.changeData02_1(name);
			this.getOption_02_1();
			this.beginRendering_02_1();
		},
		active02_1(id,name){
			var data = this.$store.state.OURDatalist;
			for(var i = 0; i < 12; i ++){//取消全部高亮
				this.mycharts_02_1.dispatchAction({
					type: "downplay",
					seriesIndex: 0, //表示series中的第几个data数据循环展示
					dataIndex: i
				});
			}
			if(name){
				for(var i = 0; i < 12; i ++){
					if(data[i].name == name){
						this.intactive02_1(-1,i);
						// console.log('点击- left：',i)
					}
				}
			}else {
				if(id == 0){
					this.intactive02_1(11,0);
					// console.log('轮播- left：',id)
				}else if(id){
					this.intactive02_1(id - 1,id);
					// console.log('轮播- left：',id)
				}
			}
			
		},
		intactive02_1(n,y){
			this.mycharts_02_1.dispatchAction({
				type: "downplay",
				seriesIndex: 0, //表示series中的第几个data数据循环展示
				dataIndex: n
			});
				// 高亮当前图形
			this.mycharts_02_1.dispatchAction({
				type: "highlight",
				seriesIndex: 0,
				dataIndex: y
			});
				// 显示 tooltip
			this.mycharts_02_1.dispatchAction({
				type: "showTip",
				seriesIndex: 0,
				dataIndex: y
			});
		},






            setTimeToDo() {
                // 饼状图实现循环高亮

                let index = -1 //高亮所在下标
                let dataLength = this.optionPie.series[0].data.length   // 当前饼图有多少个扇形
                // 用定时器控制饼图高亮
                this.mTime = setInterval(()=>{
                    // 清除之前的高亮
                    this.echartsPie.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: index
                    })
                    index = (index + 1) % dataLength
                    // 当前下标高亮
                    this.echartsPie.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: index
                    })
                    if (index > dataLength) {
                    index = 0
                    }
                }, 2000)
                // 鼠标划入
                this.echartsPie.on('mouseover',()=>{
                    // 停止定时器，清除之前的高亮
                    clearInterval(this.mTime)
                    this.echartsPie.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: index
                    })
                })

                // const data = this.option.series[0].data;
                // data.forEach(e => e.selected = false);
                // data[this.num].selected = true;
                // this.title = data[this.num].name;
                // // 必须重新设置option，因为echart没有监听data的变化
                // this.myChart.setOption(this.option);
            },
            setHighLight() {
                clearInterval(this.timer);
                const data = this.option.series[0].data;
                this.timer = setInterval(() => {
                    if (this.num < data.length - 1) {
                        this.num++
                    } else {
                        this.num = 0
                    }
                    this.setTimeToDo();
                }, 3000);
            }
        }
    }
</script>
