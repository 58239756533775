import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'




// step1：引入 axios
import Axios from 'axios'
// step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = Axios
// step3：使每次请求都会带一个 /api 前缀 
//  Axios.defaults.baseURL = '/api'



import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: '7As7TQGuTBQBMkGkkve5ptd0IphnUfUa'
})

// // 注册到全局
// Vue.component(VTable.name, VTable)
// Vue.component(VPagination.name, VPagination)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
