import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from '../views/Demo.vue'
import Test from '../views/test6.vue'



Vue.use(VueRouter)


const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Demo',
    component: Demo
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
 
  },{
    path: '/Demo',
    name: 'demo',
     component: Demo,
     meta: {
      title: '防疫大数据平台'
    }

  }


  ,{
    path: '/test',
    name: 'test',
    component: Test,
    meta: {
      title: '防疫大数据平台',
      keepAlive: true, // 需要被缓存
    }
  }
 
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})




export default router


