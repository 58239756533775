
<template>
  <div class="demo">
    <!-- 标题 -->
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />

    <div
      style="margin-top: 8%; font-size: 3rem; color: green; margin-left: 17vw"
    >
      回收总量 <span style="color: red">{{ TotalWeight }}</span> 吨
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;投放次数
      <span style="color: red">{{ TotalNumber }}</span> 次
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;设备台数
      <span style="color: red">{{ TotalDevicesNumber }}</span> 台
    </div>
    <div class="left_box">
      <div
        style="
          float: left;
          margin-top: 80px;
          margin-left: 226px;
          font-size: 1.5rem;
          color: #46cdcf;
        "
      >
        {{this.currentYear}}年总量统计
      </div>

      <div class="box1">
        <!-- 总量排行榜 -->
        <div style="width: 100%; height: 100%" ref="Echart02" id="main"></div>
      </div>

      <div
        style="
          float: left;
          margin-top: 93px;
          margin-left: 240px;
          font-size: 1.5rem;
          color: #46cdcf;
        "
      >
        设备投放趋势
      </div>
      <div class="box2">
        <!-- 设备投放趋势 -->
        <div style="width: 100%; height: 100%" ref="Echart03" id="main"></div>
      </div>
    </div>

    <div class="center_box">
      <div class="box3">
        <!-- 中心地图 -->
        <!-- <div style="width: 100%; height: 100%" ref="Echart01" id="main"></div> -->

        <div
          style="width: 80%; height: 100%; float: left"
          ref="Echart01"
          id="main"
        ></div>
        <div
          style="width: 20%; height: 100%; float: left"
          ref="rankingContainer"
          id="main"
        ></div>
      </div>
      <div>
        <div class="box4">
          <!-- 图1 -->
          <div style="width: 100%; height: 100%; overfloat: hidden">
            <img
              style="width: 100%; height: 100%"
              :src="img1"
              alt=""
            />
          </div>
        </div>
        <div class="box5">
          <!-- 图2 -->
          <div style="width: 100%; height: 100%; overfloat: hidden">
            <img
              style="width: 100%; height: 100%"
              :src="img2"
              alt=""
            />
          </div>
        </div>
        <div class="box6">
          <!-- 图3 -->
          <div style="width: 100%; height: 100%; overfloat: hidden">
            <img
              style="width: 100%; height: 100%"
              :src="img3"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div class="right_box">
      <div
        style="
          float: left;
          margin-top: 75px;
          margin-left: 220px;
          font-size: 1.5rem;
          color: #46cdcf;
        "
      >
        每月投放次数趋势
      </div>
      <div class="box7">
        <!-- 右侧地图标点 -->

        <div style="width: 100%; height: 100%" ref="Echart04" id="main"></div>
      </div>
      <div
        style="
          float: left;
          margin-top: 105px;
          margin-left: 210px;
          font-size: 1.5rem;
          color: #46cdcf;
        "
      >
        每月回收总量趋势
      </div>
      <div class="box8">
        <!-- 设备采集变化趋势 -->
        <div style="width: 100%; height: 100%" ref="Echart05" id="main"></div>
      </div>
    </div>
    <div
      style="
        float: left;
        margin-top: 30px;
        font-size: 1rem;
        white: red;
        margin-left: 43vw;
      "
    >
      技术支持：江西智民网络（集团）有限公司
    </div>
  </div>
</template>
<script>
var echarts = require("echarts");
import axios from "axios"; //使用axios来请求线上数据

import ganzhon from "../util/ganzhon.json"; //导入json文件
echarts.registerMap("ganzhon", ganzhon); //使用地图，
export default {
  name: "echarts",
  // data() 返回的属性将会成为响应式的状态
  // 并且暴露在 `this` 上
  data() {
    return {
      TotalWeight: 10,
      TotalNumber: 10,
      TotalDevicesNumber: 10,
      listData: null,
      listData2: null,
      listData3: null,
      listData4: null,
      listData5: null,
      listData6: null,
      listData7: null,
      listImages: null,
      listyear:null,
      namelist: null,
      valuelist: null,
      imgA: null,
      currentYear:null,
      img1:"",
       img2:"",
        img3:"",
      currentIndex: 0,
      intervalId: null,
      intervalYear: null,
      years:[],
    };
  },

  // methods 是一些用来更改状态与触发更新的函数
  // 它们可以在模板中作为事件处理器绑定
  methods: {
    //中心大图
    echartsInit() {
      var mydom = this.$refs.Echart01;
      var my1 = echarts.init(mydom);
      this.mycharts_01 = my1;

      var option = {
        title: {
          text: "虔金回收设备覆盖区",
          subtext: "单位（台）",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}<br/>{c} (台)",
        },
        // toolbox: {
        //   show: true,
        //   orient: "vertical",
        //   left: "right",
        //   top: "center",
        //   feature: {
        //     dataView: { readOnly: false },
        //     restore: {},
        //     saveAsImage: {},
        //   },
        // },
        visualMap: {
          min: 1,
          max: 50,
          text: ["High", "Low"],
          realtime: false,
          calculable: true,
          inRange: {
            color: ["lightskyblue", "yellow", "orangered"],
          },
        },
        series: [
          {
            name: "赣州回收设备覆盖率图表",
            type: "map",
            zoom: 1.2, //地图放大显示
            map: "ganzhon",
            label: {
              show: true,
            },
            data: this.listData,
          },
        ],
      };

      this.mycharts_01.setOption(option);
    },

    temp() {
      var mydomrank = this.$refs.rankingContainer;
      var myrank = echarts.init(mydomrank);
      this.mycharts_rank = myrank;
      // 柱状图配置
      var barOption = {
        title: {
          text: "设备数量",
          left: "center",
        },
        tooltip: {},

        grid: {
          left: "2%",
          containLabel: true,
        },
        xAxis: {
          position: "top",
          inverse: true,

          type: "value",
          name: "排名",
          splitLine: {
            show: false, // 不显示网格线
          },
        },
        yAxis: {
          type: "category",
          data: this.listData.map((item) => item.name),
          inverse: true,
          axisLabel: {
            //x轴文字的配置
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            data: this.listData.map((item) => item.value),
            itemStyle: {
              color: "#3fc1c9", // 柱状图颜色
            },
            // barWidth: '75%',
            label: {
              show: true,
              width: "100px", // 将内容的宽度固定

              position: "insideRight",
              textStyle: {
                color: "#fff",
              },
              formatter: function (params) {
                return params.name + params.value + "台";
              },
            },
          },
        ],
      };
      this.mycharts_rank.setOption(barOption);
    },

    // 总量排行榜
    echartsInit2() {
      var mydom2 = this.$refs.Echart02;
      var my1 = echarts.init(mydom2);
      this.mycharts_02 = my1;
      // 假设有一组数据
      var data = [
        { name: "赣县区", value: 10, color: "#FFB6C1" },
        { name: "章贡区", value: 20, color: "#87CEFA" },
        { name: "南康区", value: 30, color: "#98FB98" },
        { name: "兴国县", value: 40, color: "#FFD700" },
        { name: "于都县", value: 45, color: "#FFD700" },
        { name: "崇义县", value: 60, color: "#FFB6C1" },
        { name: "大余县", value: 50, color: "#FFB6C1" },
        { name: "上犹县", value: 40, color: "#FFB6C1" },
        { name: "全南县", value: 90, color: "#FFB6C1" },
      ];

      var data = this.listyear;

      // 对数据进行排序
      data.sort(function (a, b) {
        return b.value - a.value; // 按值从大到小排序
      });

      // 提取排序后的名称和值
      var xAxisData = data.map(function (item) {
        return item.name;
      });
      var seriesData = data.map(function (item) {
        return {
          value: (item.value % 1 === 0) ? item.value : item.value.toFixed(3).replace(/\.?0+$/, '') ,
          itemStyle: {
            color: item.color, // 设置不同的颜色
          },
        };
      });

      // 指定图表的配置项和数据
      var option = {
        xAxis: {
          type: "category",
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            },
          },

          data: xAxisData,
        },
        axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          textStyle: {
            color: "rgba(219, 225, 255, 1)",
          },
          formatter: function (params) {
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 1; //一行显示几个字
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                var start = p * provideNumber;
                var end = start + provideNumber;
                if (p == rowNumber - 1) {
                  tempStr = params.substring(start, paramsNameNumber);
                } else {
                  tempStr = params.substring(start, end) + "\n";
                }
                newParamsName += tempStr;
              }
            } else {
              newParamsName = params;
            }
            return newParamsName;
          },
        },

        yAxis: {
          splitLine: {
            show: false, // 不显示网格线
          },
        },
        series: [
          {
            name: "2024",
            type: "bar",
            data: seriesData,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "white",
                    fontSize: 16,
                  },
                },
              },
            },
          },
        ],
      };

      this.mycharts_02.setOption(option);
    },

    //设备投放趋势
    echartsInit3() {
      var mydom3 = this.$refs.Echart03;
      var my1 = echarts.init(mydom3);
      this.mycharts_03 = my1;

      var data = this.listData2;

      var option = {
        xAxis: {
          type: "category",
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
          data: data.map(function (item) {
            return item.name;
          }),
        },
        yAxis: {
          type: "value",
          show: true, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
          axisTick: {
            show: true, // 不显示坐标轴刻度线
          },
          axisLine: {
            show: true, // 不显示坐标轴线
          },
          axisLabel: {
            show: true, // 不显示坐标轴上的文字
          },
          splitLine: {
            show: false, // 不显示网格线
          },
        },
        series: [
          {
            data: data.map(function (item) {
              return item.value;
            }),
            type: "bar",
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "white",
                    fontSize: 16,
                  },
                },
              },
            },
          },
        ],
      };

      this.mycharts_03.setOption(option);
    },

    //
    echartsInit4() {
      var mydom4 = this.$refs.Echart04;
      var my1 = echarts.init(mydom4);
      this.mycharts_05 = my1;

      this.namelist = [];
      this.valuelist = [];

      for (var i in this.listData4) {
        //这里我展示的是后台返回的每条数据里面的bookname和num
        this.namelist.push(this.listData4[i].name);
        this.valuelist.push(this.listData4[i].value);
      }

      // 指定地理坐标系组件
      var option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.namelist,
                    axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false, // 不显示网格线
          },
        },
        series: [
          {
            name: "采集量",
            type: "line",
            smooth: true,
            stack: "Total",
            data: this.valuelist,
            areaStyle: {
              //填充的颜色
              color: {
                //线性渐变前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
                type: "linear",
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,240,170,0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,240,170,1)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
        ],
      };
      this.mycharts_05.setOption(option);
    },

    //每月重量总量趋势线
    echartsInit5() {
      var mydom4 = this.$refs.Echart05;
      var my1 = echarts.init(mydom4);
      this.mycharts_05 = my1;

      this.namelist = [];
      this.valuelist = [];
      for (var i in this.listData4) {
        //这里我展示的是后台返回的每条数据里面的bookname和num
        this.namelist.push(this.listData4[i].name);
        this.valuelist.push(this.listData4[i].value);
      }

      var option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.namelist,
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",

          splitLine: {
            show: false, // 不显示网格线
          },
        },
        series: [
          {
            name: "采集量",
            type: "line",
            smooth: true,
            stack: "Total",
            data: this.valuelist,
            areaStyle: {
              //填充的颜色
              color: {
                //线性渐变前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
                type: "linear",
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,240,170,0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,240,170,1)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
        ],
      };
      this.mycharts_05.setOption(option);
    },





    getalldata() {
      var that = this;
      //设备标识点数据
      axios
        .get("https://recovery.zhimin.group/Admin/API/GetAllList")
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            // 将JSON解析为JavaScript对象
            var jsonObj = JSON.parse(response.data.data);
            this.TotalWeight = (jsonObj[0].TotalWeight / 1000).toFixed(6);
            this.TotalNumber = jsonObj[0].TotalNumber;
            this.TotalDevicesNumber = jsonObj[0].TotalDevicesNumber;
          }
        });
    },
    //获取总量排行
    GetTotalRanking() {
      var that = this;
      //总量排行榜
      axios
        .get("https://recovery.zhimin.group/Admin/API/GetTotalRanking")
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            // 将JSON解析为JavaScript对象
            var jsonObj = JSON.parse(response.data.data);
            this.listData = jsonObj;
            console.log(this.listData);
            //
            this.echartsInit();
            this.temp();
          }
        });
    },


    //获取设备趋势
    GetDeliveryTrends() {
      var that = this;
      //总量排行榜
      axios
        .get("https://recovery.zhimin.group/Admin/API/GetDeliveryTrends")
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            // 将JSON解析为JavaScript对象
            var jsonObj = JSON.parse(response.data.data);
            this.listData2 = jsonObj;
            console.log(this.listData2);
            this.echartsInit3();
          }
        });
    },

    //获取回收次数趋势
    GetTotalWeigh() {
      axios
        .get("https://recovery.zhimin.group/Admin/API/GetTotalWeigh")
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            // 将JSON解析为JavaScript对象
            var jsonObj = JSON.parse(response.data.data);
            this.listData4 = jsonObj;

            this.echartsInit5();
          }
        });
    },

    //获取采集量趋势
    GetCollection() {
      var that = this;

      axios
        .get("https://recovery.zhimin.group/Admin/API/GetCollection")
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            // 将JSON解析为JavaScript对象
            var jsonObj = JSON.parse(response.data.data);
            this.listData4 = jsonObj;

            this.echartsInit4();
          }
        });
    },
       updateYear() {


      // 根据当前年份循环切换
      let index = this.years.indexOf(this.currentYear);
      if (index === -1 || index === this.years.length - 1) {
        index = 0;
      } else {
        index++;
      }

      // 更新当前显示的年份
      this.currentYear = this.years[index];
      
      this.GetYeardata(this.currentYear);//获取各地区年度回收总重量

    },
    getRecentYears(currentYear) {
      const years = [];
      for (let i = 0; i < 3; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
            //获取图片列表
    GetImages() {
      var that = this; 
      axios
        .get("https://recovery.zhimin.group/Admin/API/GetImages")
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            // 将JSON解析为JavaScript对象
            var jsonObj = JSON.parse(response.data.data);
            this.listImages=jsonObj;
            console.log(this.listImages);
          }
        });
    },

     //获取每年各地区总回收重量列表
    GetYeardata(year) {
      var that = this; 
      axios
        .get("https://recovery.zhimin.group/Admin/API/GetYearRanking?year="+year)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            // 将JSON解析为JavaScript对象
            var jsonObj = JSON.parse(response.data.data);
            this.listyear=jsonObj;
             this.echartsInit2();
            console.log(this.listyear);
          }
        });
    },

  },
  // 生命周期钩子会在组件生命周期的各个不同阶段被调用
  // 例如这个函数就会在组件挂载完成后被调用
  mounted() {
    this.getalldata(); //统计总数
    this.GetTotalRanking(); //获取总量排行
    this.GetDeliveryTrends(); //获取设备趋势
    this.GetTotalWeigh(); //每月回收总量趋势
    this.GetCollection(); //每月采集数趋势


    

      // 获取当前年份
      this.currentYear = new Date().getFullYear();
      this.GetYeardata(this.currentYear);//获取各地区年度回收总重量
      // 获取最近三年的年份数组
      this.years = this.getRecentYears(this.currentYear);

    // 轮询年份回收总重量
    this.intervalYear=setInterval(() => {
      this.updateYear();
    }, 30000);

    
    this.GetImages();//获取图片列表

    //轮播图片
    this.intervalId = setInterval(() => {
      if(this.listImages[this.currentIndex].src.length>3){
          this.img1=this.listImages[this.currentIndex].src;
          console.log(this.img1)
          this.currentIndex++;
      }else{
        this.currentIndex=0;
      }
      
      if(this.listImages[this.currentIndex].src.length>3){
          this.img2=this.listImages[this.currentIndex].src;
          console.log(this.img2)
          this.currentIndex++;
      }else{
        this.currentIndex=0;
      }

      if(this.listImages[this.currentIndex].src.length>3){
          this.img3=this.listImages[this.currentIndex].src;
          console.log(this.img3)
          this.currentIndex++;
      }else{
        this.currentIndex=0;
      }      
    }, 10000);

  },
};
</script>


<style lang="scss">
html {
  width: 100vw;
  height: 100vh;
}

.demo {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 auto;
  padding: 0 auto;

  justify-content: space-between;
  background: url("../assets/jpg/bg.png") no-repeat;
  background-size: cover;

  overflow: hidden;
  color: white;

  .maintop {
    width: 100vw;
    height: 9vh;
    float: left;
  }
}

.left_box {
  width: 30vw;
  float: left;
  height: 70vh;
  // background-color: red;
}
.box1 {
  margin-top: 11vh;
  margin-left: 3vw;
  margin-right: 1vw;
  height: 250px;
  // border: dashed green;
}
.box2 {
  margin-top: 12vh;
  margin-left: 3vw;
  margin-right: 1vw;
  height: 250px;
  // border: dashed green;
}
.center_box {
  width: 38vw;
  float: left;
  height: 70vh;
}

.box3 {
  margin-top: 6vh;
  margin-left: 3vw;
  margin-right: 6px;
  height: 480px;
  // border: dashed green;
}

.box4 {
  margin-top: 5vh;
  margin-left: 3vw;
  float: left;
  width: 205px;
  height: 145px;
  // border: dashed green;
}

.box5 {
  margin-top: 5vh;
  margin-left: 1.2vw;
  float: left;
  width: 205px;
  height: 145px;
  // border: dashed green;
}

.box6 {
  margin-top: 5vh;
  margin-left: 1.3vw;
  float: left;
  width: 205px;
  height: 145px;
  // border: dashed green;
}

.right_box {
  width: 30vw;
  float: left;
  height: 70vh;
}
.box7 {
  margin-top: 10vh;
  margin-left: 3vw;
  margin-right: 1vw;
  height: 250px;
  // border: dashed green;
}
.box8 {
  margin-top: 13vh;
  margin-left: 3vw;
  margin-right: 1vw;
  height: 245px;
  // border: dashed green;
}
</style>
