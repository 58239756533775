import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.prototype.$axios=axios;
Vue.use(Vuex)

export default new Vuex.Store({

  state: {	
	 // PhoneUrl:"https://localhost:5001/MapAPI",
	PhoneUrl:"https://gtm.zmdsj.ltd/MapAPI",
	usertype:"",
	userid:"",

	  MapUrl:"https://gtm.zmdsj.ltd/MapAPI",
	  mapId: -1,
	  className: null,
	//   OURDatalist:[
	// 			{ name: "章贡区", value: 200, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},               //01 02
	// 			// { name: "瑞金市1", value: 259, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},                //03
	// 			// { name: "宁都县", value: 48, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},                 //04
	// 			// { name: "赣县区", value: 348, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},    //05
	// 			// { name: "崇义县", value: 73 , passNums: null, failNums: null,count: null,Redcode: null,Yellowcode: null,Greencode: null},                 //07
	// 			// { name: "上犹县", value: 360, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},                //08
	// 			// { name: "全南县", value: 271, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},                //09
	// 			// { name: "于都县", value: 367, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},                //10
	// 			// { name: "石城县", value: 479, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},  //11
	// 			// { name: "大余县", value: 540, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},                //12
	// 			// { name: "会昌县", value: 41, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null},                 //13
	// 			// { name: "南康区", value: 420, passNums: null, failNums: null,count: null ,Redcode: null,Yellowcode: null,Greencode: null}      //14
	// 			//{ name: "兴国县", value: 102, passValue: null },
	// 			//{ name: "安远县", value: 165, passValue: null },
	// 			//{ name: "寻乌县", value: 310, passValue: null },
	// 			//{ name: "定南县", value: 87 , passValue: null},
	// 			//{ name: "龙南市", value: 46 , passValue: null},
	// 			//{ name: "信丰县", value: 600, passValue: null },
	// 		],
	OURDatalist:[],
	  useTime: [],
	  startLeft: false,
	  buttonTime: true,
	  photos:[],
	  photosUp:[],
	  photosDown:[],
	  nowday: '',
	  useSeeTime:[],
  },
  mutations: {
	setUsertype(state,val){ // 设置用户身份
		state.usertype = val 
		sessionStorage.setItem('usertype',state.usertype)
	  },
	  setUserid(state,val){ // 设置用户身份
		state.userid = val 
		sessionStorage.setItem('userid',state.userid)
	  },

	  getuseSeeTime(state,data){
		  state.useSeeTime = data;
	  },
	  getTimeButtomIsit(state,data){
		  state.buttonTime = data;
	  },
	  getMapId(state,data){
		  state.mapId = data;
		  // if(state.mapId == data - 1){
			 //  state.mapId = 0;
		  // }else{
			 //  state.mapId ++;
		  // }
		  // console.log('Vuex：' , state.mapId)
	  },
	  getClassName(state,data){
		  state.className = data;
		  // console.log('Vuex：' , data)
	  },
	  getUseTime(state,data){
		  state.useTime = data;
		  console.log('Vuex：' , data );
	  },

	  //左上
	  getOURDatas(state,data){
		  state.OURDatalist=data
		//   for(var i = 0; i < data.length; i ++){
		// 	  state.OURDatalist[i].value = data[i].value;
		// 	  state.OURDatalist[i].Redcode = data[i].Redcode;
		// 	  state.OURDatalist[i].Yellowcode = data[i].Yellowcode;
		// 	  state.OURDatalist[i].Greencode = data[i].Greencode;
		// 	  state.OURDatalist[i].count = data[i].count;
		// 	  state.OURDatalist[i].passCode = data[i].passCode;
		// 	  state.OURDatalist[i].passIDCard = data[i].passIDCard;
		//   }
		  console.log('Vuex：' , state.OURDatalist)
	  },
	  getStartLeft(state,data){
		  state.startLeft = data;
	  },
	  getPhotos(state,data){
		  var num = data.length,
			  heafNum = parseInt(num / 2);
		  
	  	  state.photos = data;
		  state.photosUp = data.slice(0,heafNum);
		  state.photosDown = data.slice(heafNum,num);
		  // console.log(state.photosUp);
		  // console.log(state.photosDown);
	  },
	  getNowday(state,data){
		 state.nowday = data;
		 console.log('Vuex：' , data );
	  },
  },
  actions: {
	  getClassName(store,data){
		  var val = data.name.replace(/[\s]+/g, "");
		  store.commit('getClassName',val)
	  },

	  
	  //获取当天照片
	  getPhotosData(store,data){
		
		//axios.get('https://localhost:5001/MapAPI/getPhotobyDate',
		   axios.get('http://gtm.zmdsj.ltd/MapAPI/getPhotobyDate',
		   //axios.get('http://datashow.zmdsj.ltd/api/DataShow/getPhotosByDate',
			{params: {
				'Date': data,//20210924
			},
		  })
		  .then((res)=>{
			  if(res.data.data.length < 100){
				  console.log('照片数量不够',data - 1)
				//   
				axios.get('http://gtm.zmdsj.ltd/MapAPI/getPhotobyDate',
				//axios.get('http://datashow.zmdsj.ltd/api/DataShow/getPhotosByDate',
				  			{params: {
				  				'Date': data - 1,
				  			},
				  })
				  .then((res)=>{
					   store.commit('getNowday',data - 1);
					   store.commit('getPhotos',res.data.data);
					  })
			  }else{
				  console.log('照片数量够',data)
				  store.commit('getNowday',data);
				  store.commit('getPhotos',res.data.data);
				  
			  }
			  // console.log(res.data.data);
		  })
	  }
  },
  modules: {
  }
})



